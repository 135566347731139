import React, { useContext } from 'react';
import {
  Box,
  Divider,
  Text,
  VStack,
  Center
} from '@chakra-ui/react';
import JournalEntry from './JournalEntry';
import Link from 'next/link';
import { storyblokEditable } from '@storyblok/react';

interface Props {
  blok: any;
  globalState: GlobalState;
}

const TripJournalEntryList = ({ blok, globalState }: Props):JSX.Element => {
  const journalId = globalState.journalTripId;
  const entryId = globalState.entryId;  
  const journalData = globalState.journalData;
  
  if (globalState.journalData.TRIPINFO.beforeTripDate === 1) return null;

  return (
    <Box {...storyblokEditable(blok)} width={{base: '90%', lg: '100%'}} >
      {
        entryId && 
        <Box mt={{ base: "1", lg: "25" }}>
          <Link href={`/mission-trip-journals/${journalId}`} passHref>
            <Text
              textAlign="center"
              as="a"
              fontSize={{ base: "2xl", lg: "4xl" }} 
            >
              Update from {journalData?.TRIPINFO?.groupname}
            </Text>
          </Link>
        
      <Divider my="5" />
    </Box>
      }

      <VStack
        gap="6"
        divider={
          <Divider
            borderColor={'brand.darkgray.200'}
            sx={{ marginBottom: '-10px!important' }}
          />
        }
      >
        {
          globalState.journalData?.ENTRYLIST.length == 0 &&
          <Text as="p" fontSize="xl" fontWeight={'bold'}>No updates have been posted at this time. Check back soon!</Text>
        }

        {
          globalState.journalData?.ENTRYLIST
          .filter((entry) => {
            if (globalState.entryId) {
              return Number(entry.journalID) === Number(globalState.entryId);
            } else {
              return true;
            }
          })
          .map((entry, index) => (
            <JournalEntry
              {...entry}
              key={index}
              entryLink={`/mission-trip-journals/${globalState.journalTripId}/${entry.journalID}`}
              isEntryPage={!!entryId}
              photoUrl={
                entry.photo === 0
                  ? ''
                  : `https://trip-journals.storage.googleapis.com/${entry.tripID}/${entry.journalID}.jpg`
              }
            />
          ))
        }
      </VStack>
    </Box>
  );
}

export default TripJournalEntryList;