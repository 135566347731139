import { Box, Button, Center, Container, Flex, Text } from "@chakra-ui/react";
import { useStripe, useElements, CardElement, AddressElement } from "@stripe/react-stripe-js";

import {  useMemo, useState } from "react";

import Image from "next/image"
import { Stripe, StripeElements } from "@stripe/stripe-js";
import { updateCustomerRecord } from "../../utils/ppm365Api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface SubscriptionFormProps {
    globalState: GlobalState,
    clientSecret: string,
    subscriptionComplete: () => void,
    supportOption: Ppm365StripePrice
}

const StripeSubscriptionForm = ({ clientSecret, globalState, subscriptionComplete, supportOption }: SubscriptionFormProps): JSX.Element => {
    const [submitting, setSubmitting] = useState(false)
    const [errorMsg, setErrorMsg] = useState<string>('');
    const [cardReady, setCardReady] = useState(false);
    const [addressReady, setAddressReady] = useState(false)
    const [submitReady, setSubmitReady] = useState(false)
    const [address, setAddress] = useState<any>({})


    const stripe = useStripe();
    const elements = useElements();

    const defaultValues = useMemo(() => {
        return {
         name: globalState?.user?.name ?? '',
         email: "",
         address: {
             line1: globalState?.user?.address1,
             line2: globalState?.user?.address2,
             city: globalState?.user?.city,
             state: globalState?.user?.state,
             country: "US",
             postal_code: globalState?.user?.zipcode
         }
        }
 },[globalState?.user?.address1, globalState?.user?.address2, globalState?.user?.city, globalState?.user?.name, globalState?.user?.state, globalState?.user?.zipcode])
 

    const confirmCardPayment = async (stripe: Stripe, elements: StripeElements) => {

        setSubmitting(true);

        const cardElement = elements.getElement(CardElement);

        stripe.confirmCardPayment(clientSecret, {
            receipt_email: globalState.user?.email,
            payment_method: {
                card: cardElement,
                billing_details: {

                    ...address
                }
            }
        }
        ).then((result) => {
            if (result.error) {
                setErrorMsg(result.error.message)
            } else {
                updateCustomerRecord({address: address.address})
                subscriptionComplete()
            }
            setSubmitting(false)
        })
    }

    const onCardElementChange = (event) => {
        if (event.complete) {
            setCardReady(true)
            if (addressReady) {
                setSubmitReady(true)
            }
        }
        else {
            setCardReady(false)
            setSubmitReady(false)
        }
    }

    const onAddressChange = (event) => {
        if (event.complete) {
            setAddressReady(true)
            setAddress(event.value)
            if (cardReady) {
                setSubmitReady(true)
            }
        }
        else {
            setAddress({})
            setAddressReady(false)
            setSubmitReady(false)
        }
    }


    return (

        <>
            {supportOption &&
                <Container>

                    <div id="payment-request-button" />
                     <Text fontWeight={"bold"}>Billing Details</Text>
                    <Flex
                        direction="column"
                        gap={5}
                      
                        mb="3"
                    >
                        <Box
                          borderColor="brand.darkgray.200"
                          borderWidth="1px"
                          borderRadius="lg"
                          padding="3">
                            <AddressElement
                                options={
                                    {
                                        defaultValues: defaultValues,
                                        mode: "billing",
                            
                                    }} onChange={onAddressChange}></AddressElement>
                        </Box>
                        <Box
                          borderColor="brand.darkgray.200"
                          borderWidth="1px"
                          borderRadius="lg"
                          padding="3">
                            <CardElement  options={{hidePostalCode: true}} onChange={onCardElementChange} />
                        </Box>
                    </Flex>

                    {errorMsg !== '' && <Text as="p" textColor="red.600" textAlign={'center'}>{errorMsg}</Text>}

                    <Center mb="3" gap="3" flexDirection={'column'}>
                        <Button
                            onClick={() => confirmCardPayment(stripe, elements)}
                            disabled={submitting || !submitReady}
                            colorScheme="brand.green"
                            leftIcon={submitting ? <FontAwesomeIcon icon={["fas", "circle-notch"]} className="fa-spin"></FontAwesomeIcon>  : undefined}
                        >
                            Sponsor ${supportOption.amount} {supportOption.type === "subscription" ? `/${supportOption.period}` : ' One-time'}
                        </Button>
                        <Image
                            src="/images/powered-by-stripe.png"
                            alt="Powered by Stripe"
                            width="119px"
                            height="26px"
                        />
                    </Center>
                </Container>
            }
        </>

    )
}

export default StripeSubscriptionForm