import {
  Container,
  Stack,
  Flex,
  Box,
  Icon,
  IconProps,
} from '@chakra-ui/react';
import { storyblokEditable } from '@storyblok/react';
import Image from 'next/image';
import { getImageSrcFromRootsId } from '../../../utils/misc';
import DynamicComponent from '../../DynamicComponent';

export default function HeroSideImage({ blok, globalState, setGlobalState, locationState, ppm365 }): JSX.Element {
  let imageSrc = blok?.image.filename;

  // override image for location pages
  if (locationState) {
    imageSrc = getImageSrcFromRootsId(locationState.id, globalState.locations);
  }

  // override image for ppm365 pages
  if (ppm365){
    imageSrc = getImageSrcFromRootsId(ppm365.location.id, globalState.ppm365Locations);
  }

  return (
    <Container 
      maxW={'container.xl'} 
      {...storyblokEditable(blok)}
      mb={{ base: 10, lg: 10 }}
    >
      <Stack
        zIndex="-1"
        align={'center'}
        spacing={{base: '50px', md: '100px', lg: 10}}
        pt={10}
        mt={{ base: '25px', md: '75px', lg: 0 }}
        direction={{ base: 'column-reverse', lg: 'row' }}
      >
        <Stack flex={1} spacing={{ base: 2, lg: 2 }}>
          {blok.content && blok.content.map((nestedBlok, index) => (
            <DynamicComponent key={index} blok={nestedBlok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
          ))}
        </Stack>
        
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          w={'full'}
          pb="50px"
        >
          <Blob
            w={'250%'}
            h={'250%'}
            position={'absolute'}
            top={'-70%'}
            left={0}
            zIndex={-1}
          />
          <Box
            position="relative"
            overflow={'hidden'}
            rounded={'xl'}
            height={{base: '225px', md: '250px'}}
            width={'full'}
            maxW={'500px'}
            boxShadow="dark-lg"
          >
            <Image
              src={`${imageSrc}/m/600x300/smart`}
              alt={'Hero Image'}
              objectFit='cover'
              objectPosition={'center center'}
              layout="fill"
              blurDataURL={`${imageSrc}/m/10x0/smart`}
              placeholder="blur"
              priority={true}
            />
          </Box>

        </Flex>
      </Stack>
    </Container>
  );
}

export const Blob = (props: IconProps):JSX.Element => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 600 600"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M485.68932937717796 457.2427239058528C439.5354826364553 478.396568103444 300.72429743544615 316.5084582605076 279.04597305393577 267.3825859663998C257.3676486724254 218.25671367229205 309.4655363473928 183.64133433879582 355.61938308811546 162.48749014120466C401.7732298288381 141.3336459436135 534.2907291167612 91.33364848674503 555.9690534982717 140.4595207808531C577.6473778797822 189.58539307496116 531.8431761179006 436.08887970826174 485.68932937717796 457.2427239058528C439.5354826364553 478.396568103444 300.72429743544615 316.5084582605076 279.04597305393577 267.3825859663998 "
        fill="url(&quot;#SvgjsLinearGradient1003&quot;)"
        transform="matrix(1.05,0,0,1.05,-19.338794708251953,-11.220141601562517)" fillOpacity="0.59"></path>
      <path
        d="M280.0103273269525 488.7211103251707C233.8564805862301 509.87495452276187 95.04529538522098 347.9868446798257 73.36697100371057 298.8609723857177C51.68864662220017 249.73510009160958 103.7865342971676 215.11972075811352 149.94038103789026 193.96587656052236C196.09422777861292 172.8120323629312 328.6117270665359 122.81203490606273 350.29005144804626 171.9379072001708C371.96837582955675 221.06377949427886 326.16417406767516 467.5672661275796 280.0103273269525 488.7211103251707C233.8564805862301 509.87495452276187 95.04529538522098 347.9868446798257 73.36697100371057 298.8609723857177 "
        fillOpacity="0.67" fill="url(&quot;#SvgjsLinearGradient1002&quot;)" opacity="1" strokeOpacity="1" strokeWidth="0"
        stroke="hsl(340, 45%, 30%)"
        transform="matrix(0.6381407812500003,-1.1052922555066969,1.1052922555066969,0.6381407812500003,-283.04284927766054,322.77804836677996)">
      </path>
      <defs>
        <linearGradient id="SvgjsLinearGradient1002" gradientTransform="rotate(192, 0.5, 0.5)">
          <stop stopColor="hsl(167, 45%, 57%)" offset="0"></stop>
          <stop stopColor="hsl(167, 45%, 80%)" offset="1"></stop>
        </linearGradient>
        <linearGradient id="SvgjsLinearGradient1003" gradientTransform="rotate(239, 0.5, 0.5)">
          <stop stopColor="hsl(167, 45%, 57%)" offset="0"></stop>
          <stop stopColor="hsl(167, 45%, 80%)" offset="1"></stop>
        </linearGradient>
      </defs>
    </Icon>
  );
};