import { Box, Text, Image, Center } from "@chakra-ui/react";
import AddThis from "../misc/AddThis";
import Fancybox from "../misc/Fancybox";

interface Ppm365UpdateProps extends Ppm365Update {
  photoUrl: string;
}

const Ppm365Update = (props: Ppm365UpdateProps):JSX.Element => {
  return (
    // the margin/padding combo seems to make it scroll the anchor into view under the heding
    <Box w="full" id={`entry-${props.journalID}`} marginTop={-100} paddingTop={100} > 
      <Text as="h4">{props.entrytitle}</Text>
      <Text>by {props.author} | {props.journalDate}</Text>
      
      <div dangerouslySetInnerHTML={{ __html: props.entry }} style={{marginBottom: '15px'}}></div>
      
      {
        props.photo === 1 &&
        <Fancybox options={{infinite: false}}>
          <Image
            cursor="pointer"
            data-fancybox="gallery"
            data-src={props.photoUrl}
            data-caption={`
              <b style="margin-bottom: 0px;">${props.entrytitle}</b><br />
              <em style="font-size: 12px;">by ${props.author}, ${props.journalDate}</em><br />
              ${props.entry.replaceAll('<p', '<p style="color:white;"')}`
            }
            src={props.photoUrl}
            alt={props.entrytitle}
            w="full"
            h="auto"
            borderRadius={'xl'}
          />
        </Fancybox>
      }

      {
        props.video === 1 &&
        <>
          <div className='embed-container'>
            <iframe src={`https://www.youtube.com/embed/${props.youtubeID}`} frameBorder='0' allowFullScreen></iframe>
          </div>
        </>
      }
      
      <Center w="full" mt="5">
        <AddThis />
      </Center>
    </Box>
  );
}

export default Ppm365Update;