import Image from "next/future/image"
import { useContext } from "react"
import { PartnerProfileContext } from "../../contexts/ppm365"
import { storyblokEditable } from "@storyblok/react";
export interface PartnerPhotoProps {
    blok: any
}

const PartnerPhoto = (props: PartnerPhotoProps): JSX.Element => {

    const profile = useContext(PartnerProfileContext)
    const blok = props.blok
    if (blok.priority === undefined) {
        blok.priority = false
    }
    let photo = ""
    switch (blok.photoType) {

        case "church":
            photo = profile.churchPhoto
            break
        case "pastor":
        default:
            photo = profile.pastorPhoto
            break;
    }

    return (
        <div className="rounded-img">
        <Image
            {...storyblokEditable(blok)}
            src={photo ? photo : '/images/praying-pelican-logo-globe.png'}
            alt={`Photo of ${profile.pastorFirstName} ${profile.pastorLastName}`}
            style={{objectFit: "contain"}}
            width={Number(blok.width)}
            height={Number(blok.height)}
            priority={blok.priority}
        ></Image>
        </div>
    )
}

export default PartnerPhoto