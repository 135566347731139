import { Box, Container, Divider, Flex, GridItem, SimpleGrid, Text, VStack } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import { Dispatch, SetStateAction } from "react";
import JournalEntry from "../journals/JournalEntry";
import ContactForm from "../misc/contact-form/ContactForm";
import DisasterResponseSubscribe from "./DisasterResponseSubscribe";

interface Props {
  blok: any;
  globalState: GlobalState;
  componentProps: ComponentSpecificProps,
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const DisasterResponseUpdates = ({ blok, globalState, setGlobalState, componentProps }: Props):JSX.Element => {
  const thisDisaster = globalState.thisDisaster;
  let entryList: JournalEntry[] = []
  if (!thisDisaster)  {
    if (!globalState.componentSpecificData || !globalState.componentSpecificData.recentDisasterUpdates) {
      return null
    }
    else {
      entryList = globalState.componentSpecificData.recentDisasterUpdates
    }

  }
  else {
    entryList = thisDisaster.entryList
  }
  
  

  return (
    <Container maxW="container.xl">
      {
        entryList.length === 0 &&
        <Flex gap="5" direction="row" justifyContent={'center'} alignItems='center' mt="10">
          <Box flexGrow="1">
            <DisasterResponseSubscribe
              blok={null}
              globalState={globalState}
            />
          </Box>
          <Box
            borderColor="brand.orange.600"
            borderWidth="1px"
            borderRadius="lg"
            p="3"
            flexGrow="1"
          >
            <Text
              color="brand.darkgray.600"
              fontWeight="bold"
              textAlign="center"
              fontSize="lg"
            >
              <FontAwesomeIcon icon="circle-info" style={{ marginRight: '5px' }} />
              REQUEST INFO
            </Text>
            <ContactForm
              blok={{ colorScheme: 'light', fieldSize: 'sm', showOptionalToggle: false, showLabels: false, submitText: 'REQUEST RELIEF INFO' }}
              globalState={globalState}
              setGlobalState={setGlobalState}
            />
          </Box>
        </Flex>
      }
      {
        entryList.length > 0 &&
        <SimpleGrid columns={{base: 1, lg: 3}} gap="10">
          <GridItem colSpan={{base: 1, lg: 2}}>
            <Box
              mb="10"
              id="updates"
              {...storyblokEditable(blok)}
            >
              <Text
                as="h2"
                my="5"
                textTransform={'uppercase'}
                color="brand.green.600"
              >
                Updates From The Field
              </Text>

              <VStack
                gap="6"
                divider={
                  <Divider
                    borderColor={'brand.darkgray.200'}
                    sx={{ marginBottom: '-10px!important' }}
                  />
                }
              >
                {
                  entryList.map((entry, index) => (
                    <JournalEntry
                      {...entry}
                      isEntryPage={false}
                      key={index}
                      photoUrl={
                        entry.photo === 0
                          ? ''
                          : `https://images.ppm.org/upload/disasterjournalpics/${entry.journalID}.jpg`
                      }
                    />
                  ))
                }
              </VStack>
            </Box>
          </GridItem>

          <GridItem colSpan={1}>
            <Flex gap="5" direction="column" pt={{base: 0, lg: 20}}>
              <DisasterResponseSubscribe 
                blok={null} 
                globalState={globalState}
              />
              <Box 
                borderColor="brand.orange.600"
                borderWidth="1px"
                borderRadius="lg"
                p="3"
              >
                <Text
                  color="brand.darkgray.600"
                  fontWeight="bold"
                  textAlign="center"
                  fontSize="lg"
                >
                  <FontAwesomeIcon icon="circle-info" style={{marginRight: '5px'}} />
                  REQUEST INFO
                </Text>
                <ContactForm 
                  blok={{ colorScheme: 'light', fieldSize: 'sm', showOptionalToggle: false, showLabels: false, submitText: 'REQUEST RELIEF INFO' }}
                  globalState={globalState} 
                  setGlobalState={setGlobalState}
                />
              </Box>
            </Flex>
          </GridItem>
        </SimpleGrid>
      }
    </Container>
    
  )
}

export default DisasterResponseUpdates;