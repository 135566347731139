import { Flex, GridItem, LinkBox, LinkOverlay, SimpleGrid, Text } from "@chakra-ui/react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

interface Props {
  blok: any;
  globalState: GlobalState;
}

const DisasterResponseContent = ({ blok, globalState }:Props):JSX.Element => {
  const thisDisaster = globalState.thisDisaster;

  if (!thisDisaster) return null;

  return (
    <SimpleGrid 
      columns={{base: 1, lg: 3}} 
      {...storyblokEditable(blok)}
    >
      <GridItem 
        colSpan={2} 
        mb={{base: 5, lg: 0}} 
        pr={{base: 0, lg: 5}}
      >
        <div dangerouslySetInnerHTML={{ __html: thisDisaster.disasterInfo.details.replaceAll('<p', '<p style="color:white;"') }} />
      </GridItem>

      <GridItem 
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        pl={{ base: 0, lg: 5 }}
      >
        <Flex gap="4" direction="column">
          {
            thisDisaster.tripList.length > 0 &&
            <DisasterResponseButtonLink
              icon={["fas", "calendar-alt"]}
              title="Trip Dates"
              subtitle={`View ${thisDisaster.disasterInfo.title} Trips`}
              link={"#trip-date-list"}
            />
          }

          {
            thisDisaster.disasterInfo.embed !== '' &&
            <DisasterResponseButtonLink
              icon={["fas", "handshake"]}
              title="Donate"
              subtitle={`${thisDisaster.disasterInfo.title} Relief Efforts`}
              link={thisDisaster.disasterInfo.embed}
              target={"_blank"}
            />
          }

          {
            thisDisaster.entryList.length > 0 &&
            <DisasterResponseButtonLink
              icon={["fas", "message"]}
              title="View Updates"
              subtitle={`From The Field`}
              link={'#updates'}
            />
          }
        </Flex>
      </GridItem>
    </SimpleGrid>
  )
}

interface DisasterResponseButtonLinkProps {
  icon: IconProp;
  title: string;
  subtitle: string;
  link: string;
  target?: string;
}

const DisasterResponseButtonLink = (props: DisasterResponseButtonLinkProps):JSX.Element => {
  return (
    <LinkBox
      bgColor="brand.green.600"
      width="350px"
      height="100px"
      borderRadius="xl"
      display="flex"
      flexDirection="column"
      justifyContent={'center'}
      alignItems={'center'}
      _hover={{
        bgColor: "brand.green.500",
        borderColor: "brand.green.600",
        borderSize: '1px'
      }}
      transition={'background-color 0.1s ease-in-out'}
      cursor="pointer"
      p="3"
    >
      <Flex direction="row">
        <Text
          as="h4"
          color="white"
          textTransform={"uppercase"}
          fontWeight="bold"
        >
          <Link href={props.link} passHref >
            <LinkOverlay
              isExternal={props.target === "_blank"}
              color="white"
              sx={{
                '&:hover': {
                  textDecoration: "none",
                }
              }}
            >
              <FontAwesomeIcon icon={props.icon} color="white" style={{ marginRight: 10 }} />
              {props.title}
            </LinkOverlay>
          </Link>
        </Text>
      </Flex>

      <Text
        textTransform={"uppercase"}
        color="white"
        textAlign={'center'}
        mb="0"
        as="a"
        target={props.target ?? '_self'}
      >
        {props.subtitle}
      </Text>
    </LinkBox>
  )
}

export default DisasterResponseContent;