import { Box, Table, Thead, Tbody, Tr, Td, Th, Text, Flex, Button, useDisclosure, Spinner } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getTripDateList } from "../../utils/ppmApi";
import { useIsMounted } from "../../utils/hooks";
import RequestInfoModal from "../misc/contact-form/RequestInfoModal";
import { TableCardContent } from "./tables/Tables";
import { convertColor } from "../../utils/misc";

interface Props {
  blok: any;
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const TripDateList = ({blok, globalState, setGlobalState}:Props):JSX.Element => {
  const infoRequestModal = useDisclosure();
  const [infoRequestTrip, setInfoRequestTrip] = useState<CustomTripSearchResult | null>(null);
  const [tripList, setTripList] = useState<CustomTripSearchResult[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [submitError, setSubmitError] = useState<boolean>(false);

  const isMounted = useIsMounted();

  const headerBackgroundColor = convertColor(blok.headerBackgroundColor, '600');
  const headerTextColor = convertColor(blok.headerTextColor, '600');

  const isLeaderType = (blok.tripType && blok.tripType.some(type => type.includes('leader'))) || (blok.subTripType && blok.subTripType.some(type => type.inlcudes('leader')));

  useEffect(() => {
    setLoading(true);
    setSubmitError(false);

    try {
      const postData = {
        cost: blok.cost,
        duration: blok.duration,
        startDate: blok.startDate,
        endDate: blok.endDate,
        ppmOffice: blok.ppmOffice,
        tripCodes: blok.tripCodes,
        tripType: blok.tripType,
        location: blok.location.map(location => location.content.rootsId),
        tripSubType: blok.tripSubType
      }
  
      const tripList = getTripDateList(postData);
  
      tripList.then(data => {
        if (isMounted) {
          setTripList(data);
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
      setSubmitError(true);
    }
    setLoading(false);

  }, [isMounted, blok])

  const openRequestInfoModal = (trip: CustomTripSearchResult) => {
    setInfoRequestTrip(trip);
    infoRequestModal.onOpen();
  }

  return (
  <>
    <RequestInfoModal
      onClose={infoRequestModal.onClose}
      isOpen={infoRequestModal.isOpen}
      trip={infoRequestTrip}
      globalState={globalState}
      setGlobalState={setGlobalState}
      dontBlockScrollOnMount={true}
    />
    {
      blok.headerText && tripList && tripList.length > 0 &&
      <Text as="h4"
            bgColor={headerBackgroundColor} 
            color={headerTextColor} 
            w={'100%'}
            fontWeight={'bold'}
            paddingBottom={2}>
        {blok.headerText}
      </Text>
    }
    <Box
      w="full"
      maxHeight={blok.enableScroll ? '750px' : ''}
      overflowY={blok.enableScroll ? 'auto' : 'visible'}
      className={blok.enableScroll ? 'mini-scrollbar' : ''}
    >
      {
        submitError && 
        <Text textAlign={'center'} fontWeight="bold" textColor={'red.600'}>
          Error loading trip dates.
        </Text>
      }
      
      {
        loading && 
        <Text textAlign={'center'} fontWeight="bold">
          <Spinner size="xl" color="brand.green.600" emptyColor="brand.green.100" />
          <br />
          Loading trip dates...
        </Text>
      }
      
      {
        tripList && tripList.length > 0 &&
        <Table
          w="full"
          className="cards"
        >
          <Thead>
            <Tr>
              <Th>
                <Text as="h6">Location</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">Start Date</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">End Date</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">Airport</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">Duration</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">Cost</Text>
              </Th>
              <Th textAlign="center">
                <Text as="h6">Actions</Text>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {tripList?.map((trip) => {
              return (
                <Tr key={`${trip.hash}`}>
                  <Td fontWeight="bold" whiteSpace={{ base: 'normal', xl: 'nowrap' }}>
                    <TableCardContent label="Location" body={trip.theLocation} />
                  </Td>

                  <Td textAlign="center" whiteSpace="nowrap">
                    <TableCardContent label="Trip Date" body={trip.tripdate} />
                  </Td>

                  <Td textAlign="center" whiteSpace="nowrap">
                    <TableCardContent label="Trip Date" body={trip.enddate} />
                  </Td>

                  <Td textAlign="center">
                    <TableCardContent label="Airport" body={trip.airport} />
                  </Td>

                  <Td textAlign="center" whiteSpace="nowrap">
                    <TableCardContent label="Duration" body={`${trip.duration}D/${trip.duration - 1}N`} />
                  </Td>

                  <Td
                    fontWeight={"bold"}
                    textAlign="center"
                    whiteSpace="nowrap"
                  >
                    <TableCardContent label="Cost" body={trip.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3)} />
                  </Td>

                  <Td  w={{ base: 'auto', lg: isLeaderType ? '360px' : 'auto'}}>
                  {trip.limitedAvailability === 0 || trip.limitedAvailability === undefined ?
                    <Flex direction={{ base: 'column', lg: 'row' }} gap="3" justifyContent={'center'} alignItems={'center'}>
                     <Link href={`https://register.ppm.org/${trip.hash.replace('A', '')}`} passHref >
                        <Button
                          as="a"
                          sx={{ '&:hover': { textDecoration: 'none' } }}
                          target="_blank"
                          size="sm"
                          colorScheme="brand.green"
                          variant="outline"
                          flexGrow={{ base: 0, lg: 1 }}
                          w={{ base: 'full', lg: 'auto' }}
                          leftIcon={<FontAwesomeIcon icon={['fas', 'edit']} />}
                        >
                          Register{isLeaderType ? '' : ' Group'}
                        </Button>
                      </Link>

                      <Button
                        size="sm"
                        colorScheme="brand.orange"
                        flexGrow={{ base: 0, lg: 1 }}
                        w={{ base: 'full', lg: 'auto' }}
                        leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                        onClick={() => {
                          openRequestInfoModal(trip);
                        }}
                      >
                        Request Info
                      </Button>
                    </Flex>
                    :  ( //Limited Availability
                      <Flex direction="row" gap="3" justifyContent={'center'} alignItems={'center'}>
                        <Button
                          size="sm"
                          colorScheme="brand.orange"
                          flexGrow={{ base: 0, xl: 1 }}
                          w={{ base: 'full', xl: 'auto' }}
                          leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                          onClick={() => {
                            openRequestInfoModal(trip);
                          }}
                        >
                          Limited Availability - Request Info
                        </Button>
                      </Flex>)
                      }
                  </Td>
                </Tr>
              )
            }
            )}
          </Tbody>
        </Table>
      }
    </Box>
  </>
  )
}

export default TripDateList;