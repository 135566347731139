import { storyblokEditable } from '@storyblok/react';
import {
  Box,
  Flex,
} from '@chakra-ui/react';
import DynamicComponent from '../../DynamicComponent';
import Image from 'next/image';

const HeroBackgroundImage = ({ blok, globalState, setGlobalState, locationState, test }) => {
  // change to grayscale if you want to use a color filter gradient
  // const photoUrl = blok.image.focus && blok.image.focus !== '' ?
  //   `${blok.image.filename}/m/1920x0/filters:focal(${blok.image.focus}):grayscale()` : 
  //   `${blok.image.filename}/m/1920x0/smart/filters:grayscale()`;

  // const blurUrl = blok.image.focus && blok.image.focus !== '' ?
  // `${blok.image.filename}/m/20x0/filters:focal(${blok.image.focus}):grayscale()` :
  // `${blok.image.filename}/m/20x0/smart/filters:grayscale()`;
  
  const photoUrl = blok.image.focus && blok.image.focus !== '' ?
    `${blok.image.filename}/m/1920x0/filters:focal(${blok.image.focus}):quality(30)` : 
    `${blok.image.filename}/m/1920x0/smart/filters:quality(30)`;

  const blurUrl = blok.image.focus && blok.image.focus !== '' ?
  `${blok.image.filename}/m/20x0/filters:focal(${blok.image.focus})` :
  `${blok.image.filename}/m/20x0/smart`;

  return (
    <Box
      w={'full'}
      minH={{base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
      height={'full'}
      {...storyblokEditable(blok)}
    >
      <Box
        w={'full'}
        minH={{ base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
        position="absolute"
        overflow={'hidden'}
        zIndex={-1}
      >
        <Box height="full">
          {
            blok.image.filename &&
            <Image
              src={photoUrl}
              blurDataURL={blurUrl}
              placeholder="blur"
              alt={blok.image.alt}
              objectFit='cover'
              objectPosition={`center ${blok.imageVerticalAlign}`}
              layout="fill"
              priority={blok.priority === "true"}
            />
          }
        </Box>
      </Box>

      <Flex
        w={'full'}
        direction="column"
        minH={{base: `${blok.minimumHeightMobile ?? '50vh'}`, lg: `${blok.minimumHeightDesktop ?? '50vh'}`}}
        justifyContent={blok.contentVerticalAlign ? blok.contentVerticalAlign : 'center'}
        alignItems={'center'}
        px={{ base: 4, md: 8 }}
        bgGradient={'linear(to-r, blackAlpha.600, blackAlpha.600)'}
        // TODO: color filter gradient options, can be set up as an option in storyblok
        // bgGradient={'linear(to-b, rgba(85, 193, 172, .3), rgba(247, 148, 29, .3))'}
        // bgGradient={'linear(to-r, rgba(247, 148, 29, .6), rgba(247, 148, 29, .6))'}
        // bgGradient={'linear(to-r, rgba(247, 148, 29, .6), rgba(150, 150, 150, .6), rgba(85, 193, 172, .6))'}
      >
        {blok.content?.map((nestedBlok, index) => (
          <DynamicComponent key={index} blok={nestedBlok} globalState={globalState} setGlobalState={setGlobalState} locationState={locationState} />
        ))}
      </Flex>
    </Box>
  );
}

export default HeroBackgroundImage;