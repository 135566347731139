import { Box, Center, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import { useMemo } from "react";
import { getCroppedPhotoSrc } from "../../utils/misc";
import RichText from "./RichText";

const PersonalBio = ({blok}) => {
  const richTextBlok = {
    content: blok.bio,
    alignment: 'left',
    textColor: 'brand.darkgray',
    textSaturation: '600',
    textShadow: 0
  }

  const photoSize = 300;

  const { photoUrl, blurUrl } = useMemo(() => getCroppedPhotoSrc(blok.photo, photoSize, photoSize), [blok.photo]);

  return (
    <SimpleGrid 
      {...storyblokEditable(blok)}  
      columns={{base: 1, lg: 3}}
      gap={{base: 0, lg: 5}}
    >
      <GridItem
        colSpan={1}
        minW={photoSize}
        my="5"
      >
        {
          blok.photo.filename && 
          <Center h="100%">
            <Box
              height={{base: photoSize * .75, lg: photoSize}}
              width={{base: photoSize * .75, lg: photoSize}}
              borderRadius="full"
              overflow="hidden"
              borderWidth="4px"
              borderColor="brand.orange.100"
              boxShadow="lg"
            >
                <Image 
                  src={photoUrl}
                  alt={blok.photo.alt}
                  width={photoSize}
                  height={photoSize}
                  blurDataURL={blurUrl}
                  placeholder="blur"
                  className="transition"
                />
            </Box>
          </Center>
        }
      </GridItem>

      <GridItem
        colSpan={{base: 1, lg: 2}}
        p={5}
      >
        <Flex
          flexDirection="column"
          justifyContent={{base: 'center'}}
          alignItems={{ base: 'center', lg: 'flex-start' }}
        >
          <Text as="h3">
            {blok.name}
          </Text>
          
          <Text
            as="h4"
            fontSize="sm"
            color="brand.green.600"
            textTransform={'uppercase'}
            mt={0}
            mb={2}
          >
            {blok.title}
          </Text>
        </Flex>
        
        <RichText blok={richTextBlok} />
      </GridItem>
    </SimpleGrid>
  )
}

export default PersonalBio;