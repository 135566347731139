import { storyblokEditable } from "@storyblok/react";
import { Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import Link from "next/link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";

interface LocationTripPricingProps {
  blok: any;
  globalState: GlobalState;
  locationState: RootsLocation;
}

const LocationTripPricing = ({ blok, globalState, locationState }: LocationTripPricingProps):JSX.Element => {
  return (
    <TableContainer 
      {...storyblokEditable(blok)} 
      borderRadius="xl"
    >
      <Table>
        <Thead
          color="whiteAlpha.800"
          backgroundColor="brand.green.600"
        >
          <Tr
            _hover={{
              backgroundColor: 'blackAlpha.100'
            }}
          >
            <Th textAlign="center" borderBottomColor="brand.darkgray.600" borderRightWidth="1px" borderRightColor={'brand.darkgray.600'} maxWidth="sm">
              <Text as="h2" color="white" fontWeight="bold" >
                Trip Pricing
              </Text>
              <Text as="p" color="white" fontSize={'2xl'} fontWeight="bold" >
                2025
              </Text>
            </Th>
            { 
              locationState?.packages.map((tripPackage, index) => (
                <Th
                  key={index} 
                  textAlign="center" 
                  fontWeight="bold" 
                  borderColor="brand.darkgray.600"
                  backgroundColor="blackAlpha.200"
                  borderRight={index === locationState?.packages.length - 1 ? '0px' : '1px'}
                  borderRightColor="brand.darkgray.600"
                  minW={'sm'}
                >
                  <Text as="h3" color="white" mb="2">
                    ${tripPackage.cost}/person
                  </Text>
                  <Text as="p" color="white" mb="0">
                    {tripPackage.package_name}
                  </Text>
                </Th>
              ))
            }
          </Tr>
        </Thead>
        <Tbody
          color="whiteAlpha.800"
          backgroundColor="brand.darkgray.700"
        >
          <DurationRow packages={locationState?.packages} />
          <RegiFeeRow packages={locationState?.packages} />
          <MaterialsRow packages={locationState?.packages} />
          <TextCheckboxRow text={'Unlimited Consultation'} packages={locationState?.packages} field={''} />
          <TextCheckboxRow text={'Ministry Included'} packages={locationState?.packages} field={'hours'} />
          <TextCheckboxRow text={'Transportation to/from Airport and Ministry Sites'} packages={locationState?.packages} field={'transportation'} />
          <TextCheckboxRow text={'Participation in Local Church Services'} packages={locationState?.packages} field={'services'} />
          <TextCheckboxRow text={'Custom Itinerary'} packages={locationState?.packages} field={'custom'} />
          <AccommodationsRow packages={locationState?.packages} />
          <MealsRow packages={locationState?.packages} />
          <RecreationRow packages={locationState?.packages} />
        </Tbody>
      </Table>
    </TableContainer>
  );
}

const DurationRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Duration
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.days}D/{tripPackage.days - 1}N
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const RegiFeeRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Group Registration Fee
      </Text>
      <Text as="p" color="white" mb="0" fontSize="sm">
        Not included in participant cost
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          $500
        </PackageColumn>
        
      ))
    }
  </PackageRow>
)

const MaterialsRow = ({ packages }) => (
  <CheckboxRow packages={packages} field={''}>
    <Text as="p" color="white" fontWeight="bold" mb="0">
      Materials Provided
    </Text>
    
    <ul>
      <li>Online mission trip organizing toolbox</li>
      <li>Digital leader handbook</li>
      <li>Participant devotionals and guides</li>
      <li>Trip promotional materials</li>
      <li>Fundraising Ideas</li>
    </ul>
  </CheckboxRow>
)

const RecreationRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Recreation or Local Experience
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.rec}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const AccommodationsRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Sleeping Accommodations
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.sleep}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const MealsRow = ({ packages }) => (
  <PackageRow>
    <PackageInitialColumn>
      <Text as="p" color="white" fontWeight="bold" mb="0">
        Meals
      </Text>
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => (
        <PackageColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'}>
          {tripPackage.meals}
        </PackageColumn>
      ))
    }
  </PackageRow>
)

const TextCheckboxRow = ({ packages, text, field }) => (
  <CheckboxRow packages={packages} field={field}>
    <Text as="p" color="white" fontWeight="bold" mb="0">
      {text}
    </Text>
  </CheckboxRow>
)

const CheckboxRow = ({packages, children, field}:{packages:RootsPackage[], children: ReactNode, field?: string}) => (
  <PackageRow>
    <PackageInitialColumn>
      {children}
    </PackageInitialColumn>
    {
      packages && packages.map((tripPackage, index) => {
        if (field === '') {
          return <CheckboxColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'} />
        } else {
          if (field === 'hours') {
            return <TextColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'} text={`${tripPackage[field]}+ Hours`} />
          } else {
            return tripPackage[field] === 1 ?
              <CheckboxColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'} /> :
              <TextColumn key={index} borderRight={index === packages.length - 1 ? '0px' : '1px'} text={'Not Included'} />
          }
          
        }
      })
    }
  </PackageRow>
)

const CheckboxColumn = ({borderRight}) => (
  <PackageColumn borderRight={borderRight}>
    <FontAwesomeIcon icon={['fas', 'check']} />
  </PackageColumn>
)

const TextColumn = ({ borderRight, text}) => (
  <PackageColumn borderRight={borderRight}>
    {text}
  </PackageColumn>
)

const PackageRow = ({children}) => (
  <Tr _hover={{backgroundColor: 'blackAlpha.200'}}>
    {children}
  </Tr>
)

const PackageInitialColumn = ({children}) => (
  <Td borderColor="brand.darkgray.600" borderRightWidth="1px" maxWidth="5" whiteSpace="normal">
    {children}
  </Td>
)

const PackageColumn = ({ children, borderRight }) => (
  <Td 
    textAlign="center" 
    fontWeight="bold" 
    borderColor="brand.darkgray.600"
    borderRight={borderRight}
    borderRightColor="brand.darkgray.600"
    maxW={'sm'}
  >
    <Text as="p" color="whiteAlpha.800" mb="0" whiteSpace="normal">
      {children}
    </Text>
  </Td>
)

export default LocationTripPricing;
