import { Flex } from "@chakra-ui/react";
import { storyblokEditable } from "@storyblok/react";
import Image from "next/image";
import Link from "next/link";
import { useMemo } from "react";
import { getScaledPhotoSrc } from "../../utils/misc";

const ImageLink = ({ blok }) => {
  const { photoUrl, blurUrl, dimensions } = useMemo(() => getScaledPhotoSrc(blok.image, Number(blok.width) ?? 1920), [blok.image, blok.width]);
  
  if (!blok.image?.filename || blok.image?.filename === '') return null;
  
  const linkHref = blok.link.cached_url.replace(process.env.NEXT_PUBLIC_STORYBLOK_PAGES_URL, '').replace('//','/');
  
  return (
    <Flex justifyContent="center">
      <Flex
        alignItems="center"
        {...storyblokEditable(blok)}
        border={`${blok.borderWidth}px solid`}
        borderColor={`${blok.borderColor}.600`}
        borderRadius={'lg'}
        padding={2}
        width={dimensions.width}
      >
        <Link href={linkHref} passHref >
          <a style={{fontSize: 0}}>
            <Image
              src={photoUrl}
              blurDataURL={blurUrl}
              placeholder="blur"
              alt={blok.image?.alt}
              width={dimensions.width}
              height={dimensions.height}
            />
          </a>
        </Link>
      </Flex>
    </Flex>
  );
};

export default ImageLink;
