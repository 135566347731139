import { Box, Button, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import Link from "next/link";

const HomeParticipantsServing = ({blok, globalState}:{blok: any, globalState: GlobalState}) => {
  const {attendees} = globalState.tripCounts;

  if (attendees >= 25) {
    return (
      <Box {...storyblokEditable(blok)}>
        <Text color="white" as="p" fontSize="2xl">
          <FontAwesomeIcon icon="handshake" style={{ marginRight: '8px' }} />
          Teams Serving Today!
        </Text>
        
        <Text color="white" as="p">Today there are {attendees.toLocaleString()} people serving on mission trips with PPM!</Text>

        <Link href="/mission-trip-journals" passHref >
          <Button
            as="a"
            sx={{ '&:hover': { textDecoration: 'none' } }}
            colorScheme="brand.darkgray"
            w="full"
          >
            View Ongoing Trips
          </Button>
          {/* <Text color="white" as="a">Click here to view our current trip journals.</Text> */}
        </Link>
      </Box>
    )

  } else {
    return (
      <Box {...storyblokEditable(blok)}>
        <Text color="white" as="p" fontSize="2xl">
          <FontAwesomeIcon icon="handshake" style={{marginRight: '8px'}} />
          Serve Now!
        </Text>
        <Text color="white" as="p">You could have been out serving somewhere in the world today! Don&apos;t let the opportunity pass you by for another 365 days.</Text>

        <Link href="/mission-trip-dates" passHref >
          <Button
            as="a"
            sx={{ '&:hover': { textDecoration: 'none' } }}
            colorScheme="brand.darkgray"
            w="full"
          >
            View Trip Dates
          </Button>
        </Link>
      </Box>
    )
  }
}

export default HomeParticipantsServing;