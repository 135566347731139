import { Box, Table, Thead, Tbody, Tr, Td, Th, Text, Flex, Button, useDisclosure, Divider } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { TableCardContent } from "../layout/tables/Tables";
import RequestInfoModal from "../misc/contact-form/RequestInfoModal";

interface Props {
  globalState: GlobalState;
  setGlobalState: Dispatch<SetStateAction<GlobalState>>;
}

const DisasterResponseDates = ({ globalState, setGlobalState }: Props): JSX.Element => {
  const infoRequestModal = useDisclosure();
  const [infoRequestTrip, setInfoRequestTrip] = useState<CustomTripSearchResult | null>(null);
  const [tripList, setTripList] = useState<CustomTripSearchResult[]>(globalState.thisDisaster.tripList);

  useEffect(() => {
    setTripList(globalState.thisDisaster.tripList);
  }, [globalState.thisDisaster.tripList])

  const openRequestInfoModal = (trip: CustomTripSearchResult) => {
    setInfoRequestTrip(trip);
    infoRequestModal.onOpen();
  }

  return (
    <>
      <RequestInfoModal
        onClose={infoRequestModal.onClose}
        isOpen={infoRequestModal.isOpen}
        trip={infoRequestTrip}
        globalState={globalState}
        setGlobalState={setGlobalState}
        dontBlockScrollOnMount={true}
      />
      
      { tripList && tripList.length > 0 &&
        <Box w="full" id="trip-date-list">
          <Divider my="10" borderColor="brand.darkgray.400" />

          <Text
            as="h2"
            my="5"
            textTransform={'uppercase'}
            color="brand.green.600"
            textAlign={'center'}
          >
            Disaster Response Trip Dates
          </Text>
          <Table
            w="full"
            className="cards"
          >
            <Thead>
              <Tr>
                <Th>
                  <Text as="h6">Location</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">Start Date</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">End Date</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">Airport</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">Duration</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">Cost</Text>
                </Th>
                <Th textAlign="center">
                  <Text as="h6">Actions</Text>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {tripList?.map((trip) => {
                return (
                  <Tr key={`${trip.hash}`}>
                    <Td fontWeight="bold" whiteSpace={{ base: 'normal', xl: 'nowrap' }}>
                      <TableCardContent label="Location" body={trip.theLocation} />
                    </Td>

                    <Td textAlign="center" whiteSpace="nowrap">
                      <TableCardContent label="Trip Date" body={trip.tripdate} />
                    </Td>

                    <Td textAlign="center" whiteSpace="nowrap">
                      <TableCardContent label="Trip Date" body={trip.enddate} />
                    </Td>

                    <Td textAlign="center">
                      <TableCardContent label="Airport" body={trip.airport} />
                    </Td>

                    <Td textAlign="center" whiteSpace="nowrap">
                      <TableCardContent label="Duration" body={`${trip.duration}D/${trip.duration - 1}N`} />
                    </Td>

                    <Td
                      fontWeight={"bold"}
                      textAlign="center"
                      whiteSpace="nowrap"
                    >
                      <TableCardContent label="Cost" body={trip.cost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }).slice(0, -3)} />
                    </Td>

                    <Td>
                      {trip.limitedAvailability === 0 || trip.limitedAvailability === undefined ?
                        <Flex direction={{ base: 'column', lg: 'row' }} gap="3" justifyContent={'center'} alignItems={'center'}>
                          <Link href={`https://register.ppm.org/${trip.hash.replace('A', '')}`} passHref >
                            <Button
                              as="a"
                              sx={{ '&:hover': { textDecoration: 'none' } }}
                              target="_blank"
                              size="sm"
                              colorScheme="brand.green"
                              variant="outline"
                              flexGrow={{ base: 0, lg: 1 }}
                              w={{ base: 'full', lg: 'auto' }}
                              leftIcon={<FontAwesomeIcon icon={['fas', 'edit']} />}
                            >
                              Register Group
                            </Button>
                          </Link>

                          <Button
                            size="sm"
                            colorScheme="brand.orange"
                            flexGrow={{ base: 0, lg: 1 }}
                            w={{ base: 'full', lg: 'auto' }}
                            leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                            onClick={() => {
                              openRequestInfoModal(trip);
                            }}
                          >
                            Request Info
                          </Button>
                        </Flex>
                        : ( //Limited Availability
                          <Flex direction="row" gap="3" justifyContent={'center'} alignItems={'center'}>
                            <Button
                              size="sm"
                              colorScheme="brand.orange"
                              flexGrow={{ base: 0, xl: 1 }}
                              w={{ base: 'full', xl: 'auto' }}
                              leftIcon={<FontAwesomeIcon icon={['fas', 'message']} />}
                              onClick={() => {
                                openRequestInfoModal(trip);
                              }}
                            >
                              Limited Availability - Request Info
                            </Button>
                          </Flex>)
                      }
                    </Td>
                  </Tr>
                )
              }
              )}
            </Tbody>
          </Table>
        </Box>
      }
    </>
  )
}

export default DisasterResponseDates;